$(document).ready(function () {
  $('.sidenav .a1.active').parents('li').addClass('ui-tabs-active');

  //Search Redirect
  $('#top-search input').on('keypress', function (e) {
    if (e.which == 13) {
      var query = $('#top-search input').val();
      window.location.href = "/Search/?q=" + query;
      return false;
    }
  });

  $('#myModal').on('hidden.bs.modal', function () {
    $(this).find('.modal-body').empty();
  });

  $('a[data-toggle="modal"]').on('click', function () {
    var modalContent = "";

    if ($(this).data('youtube')) {
      modalContent = '<iframe id="ytplayer" type="text/html" width="640" height="360" src="' + $(this).data('modal-video') + '" frameborder="0"></iframe>';
    } else {
      modalContent = '<video controls="" autoplay="" name="media" style="max-width: 100%;"><source src="' + $(this).data('modal-video') + '" type="video/mp4"></video>';
    }

    $('.modal-body').html(modalContent);
  });

  //..........................................//
  //..........Find a Foodbank page............//
  //..........................................//
  $('.show-details').on('click', function (e) {
    e.preventDefault();

    var modal = '.agency-details-modal';
    var map;

    var agency = $(this).data('details').split(':_:');

    var name = agency[0];
    var address = agency[4];
    var city = agency[1];
    var state = agency[2];
    var zip = agency[3];
    var county = agency[5];
    var phone = agency[6];
    var fax = agency[7];
    var services = agency[8];
    var hours = agency[9];
    var lat = agency[10];
    var long = agency[11];

    $(modal + ' .name').text(name);
    $(modal + ' .address').text(address);
    $(modal + ' .city').text(city);
    $(modal + ' .state').text(state);
    $(modal + ' .zip').text(zip);
    $(modal + ' .county').text(county);
    $(modal + ' .phone').text(phone);
    $(modal + ' .fax').text(fax);
    $(modal + ' .services').text(services);
    $(modal + ' .hours').empty().append(hours);

    var geocoder = new google.maps.Geocoder();
    var fullAddress = address + " " + city + ", " + state + " " + zip;

    var directionsLink = "https://www.google.com/maps/dir//" + fullAddress + "/";

    $(modal + ' #directions-link').attr('href', directionsLink);

    geocoder.geocode({
      'address': fullAddress
    }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        lat = results[0].geometry.location.lat();
        long = results[0].geometry.location.lng();
        renderModalMap(lat, long);
      }
    });

    $(modal).attr('style', 'display: block');

  });

  $('.agency-details-modal .close').on('click', function () {
    $('.agency-details-modal').attr('style', 'display: none');
  });

  //..........................................//
  //..........Meet The Team page............//
  //..........................................//

  $('.show-bio').on('click', function (e) {
    e.preventDefault();

    var modal = '.bio-modal';

    var emp_bio = $(this).data('bio').split(':_:');

    var name = emp_bio[0];
    var title = emp_bio[1];
    var picture = emp_bio[2];
    var bio = emp_bio[3];

    var htmlPic = "<img src='" + picture + "' alt='" + name + "' />";

    $(modal + ' .name').text(name);
    $(modal + ' .title').text(title);
    $(modal + ' .team-image').html(htmlPic);
    $(modal + ' .team-bio').html(bio);

    $(modal).attr('style', 'display: block');

  });

  $('.bio-modal .close').on('click', function () {
    $('.bio-modal').attr('style', 'display: none');
  });
  //.........................................................//
  //......Harvest Ball Registration / Sponsorship Forms......//
  //.........................................................//
  var numGuests = 0;
  var totalGuests = 0;
  var availSeats = 0;
  var totalCost = 0;

  //When reservation type is changed
  $('.sel-res-type').on('change', function () {
    var resType = $('.sel-res-type option:selected').text().trim();

    if (resType.indexOf('Individual') >= 0) {
      $('.res-type-tbl').addClass('hidden');
      $('.res-type-ind').removeClass('hidden');

      totalGuests = $('.res-type-ind select').val().trim();
      totalCost = totalGuests * 100;
    } else {
      $('.res-type-tbl').removeClass('hidden');
      $('.res-type-ind').addClass('hidden');

      var totalTables = $('.res-type-tbl select').val().trim();
      totalGuests = totalTables * 8;
      totalCost = totalTables * 800;
    }
    $('.pmt-total p').text("Total: $" + totalCost);
    changeSeatsAvailable(totalGuests);
  });

  //When number of reservations is changed
  // -- Individual
  $('.res-type-ind').on('change', function () {
    totalGuests = $('.res-type-ind select').val().trim();
    totalCost = totalGuests * 100;
    changeSeatsAvailable(totalGuests);
    $('.pmt-total p').text("Total: $" + totalCost);
  });

  // -- Table
  $('.res-type-tbl').on('change', function () {
    var totalTables = $('.res-type-tbl select').val().trim();
    totalGuests = totalTables * 8;
    totalCost = totalTables * 800;
    changeSeatsAvailable(totalGuests);
    $('.pmt-total p').text("Total: $" + totalCost);
  });

  //Listen for new lines to reduce seats available
  $('.res-guests textarea').on('keyup', function (e) {
    availSeatsTxt = "<span class='seats-available'>Seats Available: ";
    numGuests = getNumberofGuests();
    changeSeatsAvailable(totalGuests);
  });

  //Contact Later about Guests Checkbox
  $('.contact-later').on('change', function () {
    if ($('.contact-later input')[0].checked) {
      $('.res-guests').addClass('hidden');
    } else {
      $('.res-guests').removeClass('hidden');
    }
  });

  //Payment Section
  $('.pmt-method').on('change', function () {
    var method = $('.pmt-method select').val().trim();

    if (method == "Credit Card") {
      $('.pmt-card').removeClass('hidden');
      //Billing Info Section
      $('#5').removeClass('hidden');
      $('#8').removeClass('hidden');
      $('.pmt-check').addClass('hidden');
    } else {
      $('.pmt-card').addClass('hidden');
      //Billing Info Section
      $('#5').addClass('hidden');
      $('#8').addClass('hidden');
      $('.pmt-check').removeClass('hidden');
    }
  });

  //Billing Info Section
  $('.copy-contact input').on('change', function () {
    var copy = $(this)[0].checked;
    if (copy) {
      $('.copy').addClass('hidden');
    } else {
      $('.copy').removeClass('hidden');
    }
  });

  //Sponsorship Level Changed
  $('.sponsorship-level select').on('change', function () {
    var sponLvl = $('.sponsorship-level select').val();
    var total = sponLvl.substr(sponLvl.indexOf('-') + 2, sponLvl.length);

    $('.pmt-total p').text("Total: " + total);
  });

  $('.button.button-black').on('click', function () {
    $('.position-input input').val($(this).data('position'));
  });

  loadPositionSelect();
});

function loadPositionSelect() {
  if ($('body').hasClass('Employment')) {
    var positions = $('div[id*="Employment"] h3');

    var selectHTML = '<option selected="selected" value="Select a Position ">Select a Position</option>';
    for (var i = 0; i < positions.length; ++i) {
      selectHTML += '<option value="' + positions[i].innerHTML + '">' + positions[i].innerHTML + '</option>';
    }
  }
}

function renderModalMap(latCoords, lngCoords) {
  var location = {
    lat: latCoords,
    lng: lngCoords
  };

  map = new google.maps.Map(document.getElementById('map'), {
    center: location,
    zoom: 14,
    disableDefaultUI: true,
    gestureHandling: 'cooperative'
  });
  var marker = new google.maps.Marker({
    position: location,
    map: map
  });
}

var substringMatcher = function (strs) {
  return function findMatches(q, cb) {
    var matches, substringRegex;

    // an array that will be populated with substring matches
    matches = [];

    // regex used to determine if a string contains the substring `q`
    substrRegex = new RegExp(q, 'i');

    // iterate through the pool of strings and for any string that
    // contains the substring `q`, add it to the `matches` array
    $.each(strs, function (i, str) {
      if (substrRegex.test(str)) {
        matches.push(str);
      }
    });

    cb(matches);
  };
};

function changeSeatsAvailable(totalGuests) {
  var availSeatsTxt = "<span class='seats-available'>Seats Available: ";
  var numGuests = getNumberofGuests();
  var availSeats = totalGuests - numGuests;

  availSeatsTxt += availSeats + "/" + totalGuests + "</span>";
  $('.res-guests label .seats-available').remove();
  $('.res-guests label').append(availSeatsTxt);
}

function getNumberofGuests() {
  if ($('.res-guests textarea').val().trim().length > 0) {
    return $('.res-guests textarea').val().split(/\r|\r\n|\n/).length;
  } else {
    return 0;
  }
}